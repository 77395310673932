<template>
	<div class="row" v-if="local_lot">
		<div class="col-12 d-flex">
			<div class="ml-auto">	
				<b-button @click="setUpControlsLot(lot)" variant="secondary">{{ $t('monte.congelation.controle_qualite') }}</b-button>
			</div>
		</div>
		<div class="col-6">
			<label for="date">{{ $t('monte.date') }}</label>
            <e-datepicker id="date" v-model="local_lot.semencelot_date" />
		</div>

		<div class="col-6">
			<label>{{ $t('monte.congelation.semencelot_quantieme') }}</label>
			<b-input type="text" class="form-control" v-model="local_lot.semencelot_quantieme"/>
		</div>

		<div class="col-6">
			<label>{{ $t('monte.congelation.volume') }}</label>
			<b-input type="text" class="form-control" v-model="local_lot.semencelot_volume"/>
		</div>

		<div class="col-6">
            <label for="couleur" class="col-form-label">{{ $t("monte.congelation.couleur") }}</label>
            <e-select
                v-model="local_lot.semencelot_couleur"
                id="couleur"
                track-by="semencecouleur_code"
                :placeholder="$t('monte.stock_semence.selectionnez_couleur')"
                :selectedLabel="$t('global.selected_label')"
                :options="all_couleurs"
                :searchable="true"
                :allow-empty="false"
                :show-labels="false"
            >
            	<template slot="option" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
               	<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.couleur.'+option.semencecouleur_code) }}</template>
               	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
        </div>

		<div class="col-6">
            <label for="dilueur" class="col-form-label">{{ $t("monte.congelation.dilueur") }}</label>
            <e-select
                v-model="local_lot.semencelot_dilueur"
                id="dilueur"
                track-by="semencedilueur_id"
                :placeholder="$t('monte.congelation.selectionnez_dilueur')"
                :selectedLabel="$t('global.selected_label')"
                :options="all_dilueurs"
                label="semencedilueur_label"
                :searchable="true"
                :allow-empty="false"
                :show-labels="false"
            />
        </div>

		<div class="col-6">
			<label>{{ $t('monte.congelation.observation') }}</label>
			<b-input type="text" class="form-control" v-model="local_lot.semencelot_observation"/>
		</div>

		<div class="col-6">
			<label>{{ $t('monte.congelation.comportement') }}</label>
			<b-input type="text" class="form-control" v-model="local_lot.semencelot_comportement"/>
		</div>

		<div class="col-6">
			<label>{{ $t('monte.congelation.commentaire') }}</label>
			<b-input type="text" class="form-control" v-model="local_lot.semencelot_commentaire"/>
		</div>

		<div class="col-12 text-center mt-2">
			<button class="btn btn-primary" @click="checkForm">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				<font-awesome-icon v-else :icon="['far', 'save']" />
				{{ $t('global.sauvegarder') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'EditLot',
		mixins: [Congelation, StockSemence, ShutterCongelation, Shutter],
		props: ['lot'],
		data () {
			return {
				local_lot: null,
				all_couleurs: [],
				all_dilueurs: [],
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.all_couleurs = await this.getAllSemenceCouleur()
				this.all_dilueurs = await this.getAllDilueurs()
				const lot = this.lot
				lot.semencelot_couleur = lot.semencelot_couleur ? this.all_couleurs.find(col => col.semencecouleur_id == lot.semencelot_couleur) : null
				lot.semencelot_dilueur = lot.semencelot_dilueur ? this.all_dilueurs.find(d => d.semencedilueur_id == lot.semencelot_dilueur) : null
				lot.semencelot_date = new Date(lot.semencelot_date)
				this.local_lot = lot
			},

			async checkForm() {
				this.processing = true

				const params = {
					...this.local_lot,
					semencelot_dilueur: this.local_lot.semencelot_dilueur ? this.local_lot.semencelot_dilueur.semencedilueur_id : null,
					semencelot_couleur: this.local_lot.semencelot_couleur ? this.local_lot.semencelot_couleur.semencecouleur_id : null
				}

				await this.editLot(this.lot.semencelot_id, params)
				this.processing = false
				this.ok()
			}
		}
	}

</script>